.Footer {
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #402e32;
}

.Footer-t1{
    color: antiquewhite;
    font-size: 1.3em;
    font-weight: 600;
}

.Footer .social-icons a{
    font-size: 1.3em;
    padding: 0 12px 0 0;
    color: antiquewhite;
}
.Footer .social-icons a:hover{
    color: rgb(255, 225, 186);
}

