@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap'); 

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}
body, html{
    background: #fff2df;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

section {
    width: 100%;
    min-height: 45vh;
}





