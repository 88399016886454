.form-login{
    background-color: aliceblue;
    height: 50vh;
    width: 50%;
    margin: 100px auto;
}

.leftSide{
    padding-top: 20%;
    width: 30%;
    background-color: brown;
    height: 50vh;
    display: inline
}

.rightSide{
    padding-top: 0;
    margin-left: 50%;
}