.navbar{
    background-color: #402E32;

}
.logo {
    color: antiquewhite;
}


.Register:hover{
    background-color: red;
}