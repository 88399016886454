.hero{
    background-image: url("../../components/Media/Hero2.jpg");
    height: 91vh;
    background-position: center;
    background-size: cover;
    
}

.text-h5{
    text-align: left;
    position: absolute;
    top: 50%;
    left: 10.5%;
    transform: translate(-50%, -50%);
    color: black;
    text-transform: uppercase;
}

.text-h6{
    text-align: left;
    position: absolute;
    top: 58%;
    left: 22%;
    transform: translate(-50%, -50%);
    color: black;
}

.btn-home{
    text-align: left;
    position: absolute;
    top: 65%;
    left: 12.2%;
    transform: translate(-50%, -50%);
    color: #dfaf7300;
    border-color: #000;
}